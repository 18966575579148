import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { SharePreview } from '../components/SlipShare';
import { useSlipShareRoutePage } from '../submodules/slip-share/composables';
import { useShareEventLogging } from '../composable';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SlipShareRoutePage',
    setup (__props) {
        const { bookingCode, imageUrl, shareLink, amountSwitcherIsVisible, showAmountEnabled, isSharingPlacedBet, isPending, errorMessage, closeModal, canselShare, onShowAmountClick } = useSlipShareRoutePage();
        const { logShareSuccess } = useShareEventLogging();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['share-page-wrapper']]: true,
                    [_ctx.$style['share-page-wrapper--centered']]: false
                }),
                onVnodeBeforeUnmount: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(canselShare) && _unref(canselShare)(...args);
                })
            }, [
                _createVNode(_unref(SharePreview), {
                    "is-loading": _unref(isPending),
                    "booking-code": _unref(bookingCode),
                    "image-url": _unref(imageUrl),
                    "share-link": _unref(shareLink),
                    "show-stake-amount-switcher": _unref(amountSwitcherIsVisible),
                    "show-amount-enabled": _unref(showAmountEnabled),
                    "is-sharing-placed-bet": _unref(isSharingPlacedBet),
                    "error-message": _unref(errorMessage),
                    onDone: _unref(closeModal),
                    onShowAmountClick: _unref(onShowAmountClick),
                    onShareSuccess: _unref(logShareSuccess)
                }, null, 8, [
                    "is-loading",
                    "booking-code",
                    "image-url",
                    "share-link",
                    "show-stake-amount-switcher",
                    "show-amount-enabled",
                    "is-sharing-placed-bet",
                    "error-message",
                    "onDone",
                    "onShowAmountClick",
                    "onShareSuccess"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'SlipShareRoutePage'
                ]
            ]);
        };
    }
});
