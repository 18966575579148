import { computed, ref, watch, onBeforeUnmount } from 'vue';
import { SlipTypeId } from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { Timer } from '@leon-hub/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { SlipListBettingItemStatus } from '../enums';
export default function useSlipListBettingItem(props, emit) {
    const { $translate } = useI18n();
    const bus = useEventsBus();
    const isUnavailableState = computed(()=>props.status !== SlipListBettingItemStatus.DEFAULT);
    const isBlocked = computed(()=>props.status === SlipListBettingItemStatus.BLOCKED || props.status === SlipListBettingItemStatus.LIMIT);
    const isPaused = computed(()=>props.status === SlipListBettingItemStatus.PAUSED);
    const showBankerButton = computed(()=>!!(props.betMode === SlipTypeId.SYSTEM && props.bankersAvailable && !isUnavailableState.value));
    const showErrorBlock = computed(()=>isBlocked.value || !!props.errorMessage);
    const computedErrorMessage = computed(()=>{
        switch(props.status){
            case SlipListBettingItemStatus.BLOCKED:
                return $translate('JS_SLIP_UNAVAILABLE_MARKET').value;
            case SlipListBettingItemStatus.LIMIT:
                return $translate('WEB2_EVENT_CARD_LIMIT_WARNING').value;
            case SlipListBettingItemStatus.PAUSED:
                return '';
            default:
                return !props.errorMessage || props.errorMessageHidden || isPaused.value && isBlocked.value ? '' : props.errorMessage;
        }
    });
    const wrapperRef = ref();
    const isCollapseAnimationStarted = ref(false);
    const emitDeleteClick = ()=>{
        emit('delete-click');
    };
    const emitBankerClick = ()=>{
        emit('banker-click');
    };
    const onDeleteAnimationEnd = ()=>{
        if (isCollapseAnimationStarted.value) emitDeleteClick();
    };
    const onDeleteButtonClick = ()=>{
        if (!props.showDeleteAnimation || !wrapperRef.value) {
            emitDeleteClick();
            return;
        }
        wrapperRef.value.style.setProperty('--initialHeight', `${wrapperRef.value.offsetHeight}px`);
        isCollapseAnimationStarted.value = true;
        bus.emit(BusEvent.SLIP_ENTRY_DELETE_CLICK, {});
    };
    /** @see onDeleteAnimationEnd */ const highlightedReplacedItem = ref(false);
    let highlightedReplacedItemTimeout = 0;
    watch(()=>props.isReplacedItem, (value)=>{
        if (value) {
            highlightedReplacedItem.value = true;
            Timer.clearTimeout(highlightedReplacedItemTimeout);
            highlightedReplacedItemTimeout = Timer.setTimeout(()=>{
                highlightedReplacedItem.value = false;
            }, props.highlightTimeout || 3000);
        }
    });
    onBeforeUnmount(()=>{
        Timer.clearTimeout(highlightedReplacedItemTimeout);
    });
    return {
        showBankerButton,
        showErrorBlock,
        computedErrorMessage,
        wrapperRef,
        isCollapseAnimationStarted,
        isUnavailableState,
        isPaused,
        isBlocked,
        highlightedReplacedItem,
        emitBankerClick,
        onDeleteAnimationEnd,
        onDeleteButtonClick
    };
}
