// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slip-message-block_myVA2{padding:40px 16px}.slip-message-block__main_EHBoJ{text-align:center}.slip-message-block__caption_HDqf9,.slip-message-block__description_GED5G{padding:0}.slip-message-block__caption_HDqf9{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;margin:0;color:var(--TextDefault);text-align:center}.slip-message-block__description_GED5G{font-size:14px;font-weight:500;line-height:24px;letter-spacing:.25px;margin:8px 0 0;color:var(--TextPrimary)}.slip-message-block__footer_akt89{margin:20px 0 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slip-message-block": `slip-message-block_myVA2`,
	"slip-message-block__main": `slip-message-block__main_EHBoJ`,
	"slip-message-block__caption": `slip-message-block__caption_HDqf9`,
	"slip-message-block__description": `slip-message-block__description_GED5G`,
	"slip-message-block__footer": `slip-message-block__footer_akt89`
};
export default ___CSS_LOADER_EXPORT___;
