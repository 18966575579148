// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slip-common-settings_eCk_5{display:flex;flex:1;flex-direction:column;padding:16px}.slip-common-settings__group_jO0W7{padding:16px;background:var(--Layer2);border-radius:10px}.slip-common-settings__group_jO0W7:not(:first-child){margin-top:16px}.slip-common-settings__checkbox_QIZzu{padding:8px 0}.slip-common-settings__collapsible_B_nrX{max-height:50px;overflow:hidden;opacity:1;transition:.3s ease-out;transition-property:opacity,max-height}.slip-common-settings__collapsible--collapsed_XBhsj{max-height:0;opacity:0}.slip-common-settings__description_w3rvA{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;padding:0;margin:0 0 10px;color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slip-common-settings": `slip-common-settings_eCk_5`,
	"slip-common-settings__group": `slip-common-settings__group_jO0W7`,
	"slip-common-settings__checkbox": `slip-common-settings__checkbox_QIZzu`,
	"slip-common-settings__collapsible": `slip-common-settings__collapsible_B_nrX`,
	"slip-common-settings__collapsible--collapsed": `slip-common-settings__collapsible--collapsed_XBhsj`,
	"slip-common-settings__description": `slip-common-settings__description_w3rvA`
};
export default ___CSS_LOADER_EXPORT___;
