import { getSafeCompetitorsValue } from './getSafeCompetitorsValue';
export function updateBetsInfoItemBySlipEntry(param) {
    let { sourceItem, entry } = param;
    return {
        // data from entry will overwrite data from runner
        competitors: getSafeCompetitorsValue(entry.competitors) ?? sourceItem.competitors,
        eventName: entry.eventName ?? sourceItem.eventName,
        runnerName: entry.runnerName ?? sourceItem.runnerName,
        marketName: entry.marketName ?? sourceItem.marketName,
        isPrimaryMarket: entry.isPrimaryMarket ?? sourceItem.isPrimaryMarket,
        // data from entry will used only if no data
        originalOdds: sourceItem.originalOdds ?? entry.odds,
        originalOddsStr: sourceItem.originalOddsStr ?? entry.oddsStr ?? void 0,
        isTotalHandicap: sourceItem.isTotalHandicap,
        oldRunnerName: sourceItem.oldRunnerName
    };
}
