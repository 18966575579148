import { computed, toRef, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { priceChangePolicyDenyChanges, priceChangePolicyAllowChanges } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent, TargetParamEnter } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { usePriceChangePolicy } from 'web/src/modules/customer/composables';
import { useFastBetsValues } from 'web/src/modules/fast-bets/composables';
import { useCurrencyStore } from 'web/src/modules/money/store';
import { useBalance } from 'web/src/modules/customer/store/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import useIsIdentifiedUser from 'web/src/modules/identification/composables/useIsIdentifiedUser';
import { useAccumulatorBoostStore } from '../submodules/accumulator-boost/store';
import useSlipUserSettings from '../submodules/slip-user-settings/store/useSlipUserSettings';
import { SettingsTabs, BetSlipMainButtonMode } from '../enums';
import { useSlipRootStore } from '../submodules/slip-root/store';
import { useSlipViewSettingsStore } from '../submodules/slip-view-settings/store';
import { useStakeInputStore } from '../submodules/stake-input/store';
import { useFreebetStore } from '../submodules/freebet/store';
import { useSlipInfoStore } from '../submodules/slip-info/store';
import { useMultiSinglesStore } from '../submodules/multi-singles/store';
import { getTaxAmount, getNettWinning } from '../utils';
// BetSlipSummary
// FastMoneyChoice
// common keyboard
// PlaceBetButton
// life-cycle
export default function useBetSlipFooter() {
    const router = useRouter();
    const slipRootStore = useSlipRootStore();
    const { handleSlipClose, handleMainButtonClick } = slipRootStore;
    const maxPrize = toRef(slipRootStore, 'maxPrize');
    const summaryStatus = toRef(slipRootStore, 'summaryStatus');
    const mainButtonMode = toRef(slipRootStore, 'mainButtonMode');
    const placeBetButtonDisabled = toRef(slipRootStore, 'placeBetButtonDisabled');
    const { isLoggedIn } = useIsLoggedIn();
    const analytics = useAnalytics();
    const { isIdentifiedUser } = useIsIdentifiedUser();
    const { priceChangePolicy, totalHandicapPriceChangePolicy, savePriceChangePolicy } = usePriceChangePolicy();
    const slipSettingsStore = useSlipUserSettings();
    const slipViewSettingsStore = useSlipViewSettingsStore();
    const { saveBetSlipConfiguration, selectSettingsTab } = slipSettingsStore;
    const { showSlipSettings, setClearBetListWarnVisibility } = slipViewSettingsStore;
    toRef(slipViewSettingsStore, 'isFullyVisibleOnMobile');
    const isHiddenOnMobile = toRef(slipViewSettingsStore, 'isHiddenOnMobile');
    const currencyStore = useCurrencyStore();
    const currencySymbol = toRef(currencyStore, 'formattedCurrency');
    const stakeInputStore = useStakeInputStore();
    const isStakeInputFocused = toRef(stakeInputStore, 'isStakeInputFocused');
    const stakeInputValue = toRef(stakeInputStore, 'stakeInputValue');
    const safeStakeValue = toRef(stakeInputStore, 'safeStakeValue');
    const isReadyToDisplayErrors = toRef(stakeInputStore, 'isReadyToDisplayErrors');
    const { balance } = useBalance();
    const formatMoney = useFormatMoney();
    const { selectFastBet, stakeInputFocus, stakeInputBlur, handleStakeInput, hideKeyboard, handleKeyboardStakeInput, backspace, numberInput, decimalSeparatorClick } = stakeInputStore;
    const freebetStore = useFreebetStore();
    const freeBetOptions = toRef(freebetStore, 'freeBetOptions');
    const freeBetSwitchChecked = toRef(freebetStore, 'freeBetSwitchChecked');
    const selectedFreeBetId = toRef(freebetStore, 'selectedFreeBetId');
    const disabledFreeBetSwitch = toRef(freebetStore, 'disabledFreeBetSwitch');
    const selectedFreeBetTermKey = toRef(freebetStore, 'selectedFreeBetTermKey');
    const showFreeBet = toRef(freebetStore, 'showFreeBet');
    const { setSelectedFreeBet, onChangeFreeBetSwitch } = freebetStore;
    const slipInfoStore = useSlipInfoStore();
    const { selectSystemValue, resetPriceChangesError } = slipInfoStore;
    const isHandicapInSelection = toRef(slipInfoStore, 'isHandicapInSelection');
    const multiSinglesStore = useMultiSinglesStore();
    const { setLocalSameStakeForSingles } = multiSinglesStore;
    // BetSlipSummary
    const formattedBalance = computed(()=>formatMoney(balance.value));
    const formattedTotalOdds = toRef(slipInfoStore, 'formattedTotalOdds');
    const warningMessage = toRef(slipInfoStore, 'warningMessage');
    const systemOptions = toRef(slipInfoStore, 'systemOptions');
    const minStake = toRef(slipInfoStore, 'minStake');
    const maxStake = toRef(slipInfoStore, 'maxStake');
    const isSyncInProgress = toRef(slipInfoStore, 'isSyncInProgress');
    const isBetModeMatchesSlipTypes = toRef(slipInfoStore, 'isBetModeMatchesSlipTypes');
    const isMultiSinglesMode = toRef(slipInfoStore, 'isMultiSinglesMode');
    const betMode = toRef(slipInfoStore, 'betMode');
    const selectedSystemValue = toRef(slipInfoStore, 'selectedSystemValue');
    const multiSinglesTotalPrice = toRef(slipInfoStore, 'multiSinglesTotalPrice');
    const maxAvailableFastBetValue = toRef(slipInfoStore, 'maxAvailableFastBetValue');
    const selectedStakeValue = toRef(slipInfoStore, 'selectedStakeValue');
    const taxPercent = toRef(slipInfoStore, 'taxPercent');
    const sameStakeForSingles = toRef(multiSinglesStore, 'sameStakeForSingles');
    const accumulatorBoostStore = useAccumulatorBoostStore();
    const formattedOddsWithoutBoost = toRef(accumulatorBoostStore, 'formattedOddsWithoutBoost');
    const maxPrizeWithoutBoost = toRef(accumulatorBoostStore, 'maxPrizeWithoutBoost');
    const boostedWinValue = toRef(accumulatorBoostStore, 'boostedWinValue');
    const prizeValue = computed(()=>boostedWinValue.value ?? maxPrize.value);
    const taxAmount = computed(()=>{
        if (!taxPercent.value) return null;
        return getTaxAmount({
            stake: safeStakeValue.value,
            winValue: prizeValue.value,
            taxPercent: taxPercent.value
        });
    });
    const nettMaxPrize = computed(()=>getNettWinning({
            stake: safeStakeValue.value,
            winValue: prizeValue.value,
            taxPercent: taxPercent.value ?? 0
        }));
    const formattedMaxPrize = computed(()=>formatMoney(nettMaxPrize.value));
    const formattedTaxAmount = computed(()=>taxAmount.value ? formatMoney(-taxAmount.value) : null);
    const formattedMaxPrizeWithoutBoost = computed(()=>maxPrizeWithoutBoost.value ? formatMoney(maxPrizeWithoutBoost.value) : '');
    const betSlipSummaryProps = computed(()=>({
            currencySymbol: currencySymbol.value,
            betMode: betMode.value,
            status: summaryStatus.value,
            stakeValue: stakeInputValue.value,
            inputFocused: isStakeInputFocused.value,
            balance: formattedBalance.value,
            minStake: minStake.value,
            maxStake: maxStake.value,
            selectedSystemValue: selectedSystemValue.value,
            totalOdds: formattedTotalOdds.value,
            totalOddsWithoutBoost: formattedOddsWithoutBoost.value,
            maxPrize: formattedMaxPrize.value,
            maxPrizeWithoutBoost: formattedMaxPrizeWithoutBoost.value,
            warningMessage: warningMessage.value,
            systemOptions: systemOptions.value,
            readyToDisplayErrors: isReadyToDisplayErrors.value,
            isSyncInProgress: isSyncInProgress.value,
            denyPriceChanges: priceChangePolicy.value === priceChangePolicyDenyChanges && isLoggedIn.value,
            denyTotalHandicapChanges: totalHandicapPriceChangePolicy.value === priceChangePolicyDenyChanges && isLoggedIn.value,
            isHandicapInSelection: isHandicapInSelection.value,
            wrongBetMode: !isBetModeMatchesSlipTypes.value,
            showFreeBet: showFreeBet.value,
            freeBetOptions: freeBetOptions.value,
            freeBetSwitchChecked: freeBetSwitchChecked.value,
            freeBetSwitchDisabled: disabledFreeBetSwitch.value,
            selectedFreeBet: selectedFreeBetId.value,
            freeBetTermKey: selectedFreeBetTermKey.value,
            isMultiSingles: isMultiSinglesMode.value,
            sameStakeForSingles: sameStakeForSingles.value,
            isLoggedIn: isLoggedIn.value,
            isEnteringAnimationPlaying: false,
            isVisible: !isHiddenOnMobile.value,
            taxPercent: taxPercent.value ? `${taxPercent.value}%` : void 0,
            taxAmount: formattedTaxAmount.value ?? void 0
        }));
    const onClearBetList = ()=>{
        hideKeyboard();
        setClearBetListWarnVisibility(true);
    };
    const allowPriceChanges = ()=>{
        resetPriceChangesError();
        savePriceChangePolicy({
            priceChangePolicy: priceChangePolicyAllowChanges
        });
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                autoUpdateCoeff: 'turnOn'
            }
        });
    };
    const allowTotalHandicapChanges = ()=>{
        // TODO any flow like resetPriceChangesError(); here?
        savePriceChangePolicy({
            totalHandicapPriceChangePolicy: priceChangePolicyAllowChanges
        });
    };
    const toggleSameStakeForSingles = ()=>{
        const newValue = !sameStakeForSingles.value;
        setLocalSameStakeForSingles(newValue);
        saveBetSlipConfiguration({
            sameStakeForSingleByDefault: newValue
        });
    };
    // FastMoneyChoice
    const { isFastBetsEnabled, fastBetsShowMax, fastBetsOptions } = useFastBetsValues();
    const fastBetsProps = computed(()=>({
            items: fastBetsOptions.value,
            currentValue: selectedStakeValue.value,
            maxValue: maxAvailableFastBetValue.value,
            minAvailableValue: minStake.value || 0,
            maxAvailableValue: maxStake.value || void 0,
            showEditButton: isLoggedIn.value,
            maxEnabled: fastBetsShowMax.value
        }));
    const editFastBets = ()=>{
        selectSettingsTab(SettingsTabs.FAST_BETS);
        showSlipSettings();
    };
    // common keyboard
    const isMobileKeyboardShown = toRef(stakeInputStore, 'isMobileKeyboardShown');
    // PlaceBetButton
    const mainButtonProps = computed(()=>({
            mode: mainButtonMode.value,
            disabled: placeBetButtonDisabled.value,
            multiSinglesTotalPrice: multiSinglesTotalPrice.value,
            isMultiSinglesMode: isMultiSinglesMode.value
        }));
    const showIdentificationModal = async ()=>{};
    const mainButtonClick = async ()=>{
        switch(mainButtonMode.value){
            case BetSlipMainButtonMode.NOT_LOGGED_IN:
                analytics.push(AnalyticsEvent.CLICK_MAP, {
                    clickCounter: {
                        enter: TargetParamEnter.BET_SLIP
                    }
                });
                await router.push({
                    name: RouteName.LOGIN
                });
                return;
            case BetSlipMainButtonMode.NOT_ENOUGH_BALANCE:
                handleSlipClose();
                if (isIdentifiedUser.value) {
                    analytics.clickMap({
                        deposit: 'betSlipDeposit'
                    });
                    await router.push({
                        name: RouteName.DEPOSITS
                    });
                } else showIdentificationModal();
                return;
            case BetSlipMainButtonMode.IDENTIFICATION_REQUIRED:
                showIdentificationModal();
                return;
            default:
                handleMainButtonClick();
        }
    };
    const onAppear = ()=>{};
    const onDisappear = ()=>{};
    onBeforeUnmount(hideKeyboard);
    return {
        // BetSlipSummary
        betSlipSummaryProps,
        stakeInputFocus,
        stakeInputBlur,
        handleStakeInput,
        selectSystemValue,
        setSelectedFreeBet,
        onChangeFreeBetSwitch,
        allowPriceChanges,
        allowTotalHandicapChanges,
        toggleSameStakeForSingles,
        onClearBetList,
        showSlipSettings,
        // FastMoneyChoice
        isFastBetsEnabled,
        fastBetsProps,
        selectFastBet,
        editFastBets,
        // common keyboard
        isMobileKeyboardShown,
        hideKeyboard,
        backspace,
        numberInput,
        decimalSeparatorClick,
        // PlaceBetButton
        mainButtonProps,
        mainButtonClick,
        // life-cycle related
        onAppear,
        onDisappear
    };
}
