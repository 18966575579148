import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, vShow as _vShow, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "title"
];
const _hoisted_2 = [
    "title"
];
const _hoisted_3 = [
    "disabled",
    "title"
];
import { useCssModule } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import createDisplayValueChangeDirective from 'web/src/utils/directives/createDisplayValueChangeDirective';
import { OddChangeVariants } from '../enums';
import useSlipListBettingItem from '../composables/useSlipListBettingItem';
const oddsUpClassName = 'slip-list-item__odd--up';
const oddsDownClassName = 'slip-list-item__odd--down';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SlipListBettingItem',
    props: {
        zeroMargin: {
            type: Boolean
        },
        oddChangeVariant: {},
        marketName: {},
        bankersAvailable: {
            type: Boolean
        },
        bankerActive: {
            type: Boolean
        },
        bankerDisabled: {
            type: Boolean
        },
        originalOdd: {},
        status: {},
        errorMessage: {},
        needToAcceptChanges: {
            type: Boolean
        },
        oldRunnerName: {},
        isReplacedItem: {
            type: Boolean
        },
        highlightTimeout: {},
        eventName: {},
        competitors: {},
        odd: {},
        runnerName: {},
        isLive: {
            type: Boolean
        },
        showDeleteAnimation: {
            type: Boolean
        },
        errorMessageHidden: {
            type: Boolean
        },
        betMode: {}
    },
    emits: [
        "delete-click",
        "banker-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { showBankerButton, showErrorBlock, computedErrorMessage, isUnavailableState, isPaused, isBlocked, wrapperRef, isCollapseAnimationStarted, highlightedReplacedItem, emitBankerClick, onDeleteAnimationEnd, onDeleteButtonClick } = useSlipListBettingItem(props, emits);
        const cssModule = useCssModule();
        const vDirection = {
            updated: createDisplayValueChangeDirective({
                moveUp: cssModule[oddsUpClassName],
                moveDown: cssModule[oddsDownClassName],
                upDirection: OddChangeVariants.UP,
                downDirection: OddChangeVariants.DOWN,
                timeout: props.highlightTimeout
            })
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "wrapperRef",
                ref: wrapperRef,
                class: _normalizeClass({
                    [_ctx.$style['slip-list-item']]: true,
                    [_ctx.$style['slip-list-item--with-remove']]: true,
                    [_ctx.$style[`slip-list-item--${_ctx.status}`]]: _unref(isUnavailableState),
                    [_ctx.$style['slip-list-item--unavailable']]: _unref(isUnavailableState),
                    [_ctx.$style['slip-list-item--collapse-animation']]: _unref(isCollapseAnimationStarted),
                    [_ctx.$style['slip-list-item--must-accept']]: _ctx.needToAcceptChanges
                }),
                onAnimationend: _cache[2] || (_cache[2] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onDeleteAnimationEnd) && _unref(onDeleteAnimationEnd)(...args);
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['slip-list-item__wrapper-inner'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['slip-list-item__container'])
                    }, [
                        _createElementVNode("button", {
                            type: "button",
                            "data-test-id": "remove-button",
                            class: _normalizeClass(_ctx.$style['slip-list-item__remove']),
                            title: _ctx.$t('WEB2_SLIP_REMOVE_ITEM'),
                            onClick: _cache[0] || (_cache[0] = //@ts-ignore
                            function() {
                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                    args[_key] = arguments[_key];
                                }
                                return _unref(onDeleteButtonClick) && _unref(onDeleteButtonClick)(...args);
                            })
                        }, [
                            _createVNode(_unref(VIcon), {
                                name: _unref(IconName).CROSS,
                                size: _unref(IconSize).SIZE_16
                            }, null, 8, [
                                "name",
                                "size"
                            ])
                        ], 10, _hoisted_1),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['slip-list-item__main'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['slip-list-item__columns'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass([
                                        _ctx.$style['slip-list-item__column'],
                                        _ctx.$style['slip-list-item__column--left']
                                    ])
                                }, [
                                    _ctx.competitors?.length ? (_openBlock(), _createElementBlock("ul", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['slip-list-item__competitors'])
                                    }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.competitors, (team)=>(_openBlock(), _createElementBlock("li", {
                                                key: team,
                                                class: _normalizeClass(_ctx.$style['slip-list-item__competitors-member'])
                                            }, _toDisplayString(team), 3))), 128))
                                    ], 2)) : (_openBlock(), _createElementBlock("p", {
                                        key: 1,
                                        class: _normalizeClass(_ctx.$style['slip-list-item__caption'])
                                    }, _toDisplayString(_ctx.eventName), 3)),
                                    _createElementVNode("p", {
                                        class: _normalizeClass(_ctx.$style['slip-list-item__market-runner'])
                                    }, [
                                        _ctx.isLive ? (_openBlock(), _createElementBlock("span", {
                                            key: 0,
                                            class: _normalizeClass(_ctx.$style['slip-list-item__live-badge'])
                                        }, " LIVE ", 2)) : _createCommentVNode("", true),
                                        _ctx.marketName ? (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 1
                                        }, [
                                            _createElementVNode("span", null, _toDisplayString(_ctx.marketName), 1),
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['slip-list-item__dash'])
                                            }, "-", 2)
                                        ], 64)) : _createCommentVNode("", true),
                                        _ctx.oldRunnerName ? (_openBlock(), _createElementBlock("span", {
                                            key: 2,
                                            class: _normalizeClass(_ctx.$style['slip-list-item__old-runner'])
                                        }, _toDisplayString(_ctx.oldRunnerName), 3)) : _createCommentVNode("", true),
                                        _createElementVNode("span", {
                                            class: _normalizeClass({
                                                [_ctx.$style['slip-list-item__runner-name']]: true,
                                                [_ctx.$style['slip-list-item__runner-name--highlighted']]: _unref(highlightedReplacedItem),
                                                [_ctx.$style['slip-list-item__runner-name--separated']]: _ctx.oldRunnerName
                                            })
                                        }, _toDisplayString(_ctx.runnerName), 3)
                                    ], 2)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass([
                                        _ctx.$style['slip-list-item__column'],
                                        _ctx.$style['slip-list-item__column--right']
                                    ])
                                }, [
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['slip-list-item__odds-container'])
                                    }, [
                                        _withDirectives((_openBlock(), _createElementBlock("p", {
                                            class: _normalizeClass({
                                                [_ctx.$style['slip-list-item__odd']]: true,
                                                [_ctx.$style[oddsUpClassName]]: !!_ctx.originalOdd && _ctx.oddChangeVariant === _unref(OddChangeVariants).UP,
                                                [_ctx.$style[oddsDownClassName]]: !!_ctx.originalOdd && _ctx.oddChangeVariant === _unref(OddChangeVariants).DOWN
                                            })
                                        }, [
                                            _ctx.originalOdd ? (_openBlock(), _createElementBlock("span", {
                                                key: 0,
                                                class: _normalizeClass(_ctx.$style['slip-list-item__original-odd'])
                                            }, _toDisplayString(_ctx.originalOdd), 3)) : _createCommentVNode("", true),
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['slip-list-item__current-odd']),
                                                title: _ctx.zeroMargin ? _ctx.$t('WEB2_SPORT_BANNER_HIGHEST_ODDS') : void 0
                                            }, [
                                                _ctx.zeroMargin ? (_openBlock(), _createBlock(_unref(VIcon), {
                                                    key: 0,
                                                    class: _normalizeClass(_ctx.$style['slip-list-item__highest-odds']),
                                                    size: _unref(IconSize).SIZE_16,
                                                    name: _unref(IconName).SPORTLINE_HIGHEST_ODDS_GRADIENT
                                                }, null, 8, [
                                                    "class",
                                                    "size",
                                                    "name"
                                                ])) : _createCommentVNode("", true),
                                                _createTextVNode(_toDisplayString(_ctx.odd), 1)
                                            ], 10, _hoisted_2)
                                        ], 2)), [
                                            [
                                                vDirection,
                                                _ctx.originalOdd ? {} : {
                                                    moveTo: _ctx.oddChangeVariant,
                                                    value: _ctx.odd
                                                }
                                            ]
                                        ])
                                    ], 2),
                                    _unref(isBlocked) || _unref(isPaused) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['slip-list-item__singles-control'])
                                    }, [
                                        _renderSlot(_ctx.$slots, "control")
                                    ], 2)),
                                    _unref(showBankerButton) ? (_openBlock(), _createElementBlock("div", {
                                        key: 1,
                                        class: _normalizeClass(_ctx.$style['slip-list-item__banker'])
                                    }, [
                                        _createElementVNode("button", {
                                            class: _normalizeClass({
                                                [_ctx.$style['slip-list-item-banker-button']]: true,
                                                [_ctx.$style['slip-list-item-banker-button--is-active']]: _ctx.bankerActive
                                            }),
                                            disabled: _ctx.bankerDisabled,
                                            title: _ctx.bankerActive ? _ctx.$t('WEB2_SLIP_BANKER_DISABLE') : _ctx.$t('WEB2_SLIP_BANKER_ENABLE'),
                                            type: "button",
                                            "data-test-id": "banker-button",
                                            onClick: _cache[1] || (_cache[1] = //@ts-ignore
                                            function() {
                                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                    args[_key] = arguments[_key];
                                                }
                                                return _unref(emitBankerClick) && _unref(emitBankerClick)(...args);
                                            })
                                        }, [
                                            _createElementVNode("span", {
                                                class: _normalizeClass([
                                                    _ctx.$style['slip-list-item-banker-button__icon']
                                                ])
                                            }, _toDisplayString(_ctx.$t('JS_SLIP_BET_EVENT_BANKER')), 3)
                                        ], 10, _hoisted_3)
                                    ], 2)) : _createCommentVNode("", true)
                                ], 2)
                            ], 2),
                            _withDirectives(_createVNode(_unref(VIcon), {
                                name: _unref(IconName).LOCK,
                                size: _unref(IconSize).SIZE_16,
                                "data-test-id": "paused-icon",
                                class: _normalizeClass(_ctx.$style['slip-list-item__icon-lock'])
                            }, null, 8, [
                                "name",
                                "size",
                                "class"
                            ]), [
                                [
                                    _vShow,
                                    _unref(isPaused)
                                ]
                            ]),
                            _withDirectives(_createElementVNode("div", {
                                "data-test-id": "blocked-message",
                                class: _normalizeClass({
                                    [_ctx.$style['slip-list-item__blocked-message']]: true,
                                    [_ctx.$style['slip-list-item__blocked-message--stake']]: !_unref(isBlocked) && _ctx.errorMessage
                                })
                            }, _toDisplayString(_unref(computedErrorMessage)), 3), [
                                [
                                    _vShow,
                                    _unref(showErrorBlock)
                                ]
                            ])
                        ], 2)
                    ], 2)
                ], 2)
            ], 34)), [
                [
                    _directive_auto_id,
                    'SlipListBettingItem'
                ]
            ]);
        };
    }
});
