// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .booking-code-load-result_ZWPrM{padding:34px 16px 40px;text-align:center}html.app__layout--desktop .booking-code-load-result__logo_T1l79{display:flex;align-items:center;justify-content:center;margin:10px 0 18px}html.app__layout--desktop .booking-code-load-result__img_dTwKp{display:block;width:auto;height:68px;object-fit:contain}html.app__layout--desktop .booking-code-load-result__header_gP3OD{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;margin:0 0 8px;color:var(--TextDefault)}html.app__layout--desktop .booking-code-load-result__text_SAXEL{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}html.app__layout--desktop .booking-code-load-result__button_Cov5K{margin:42px 0 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"booking-code-load-result": `booking-code-load-result_ZWPrM`,
	"booking-code-load-result__logo": `booking-code-load-result__logo_T1l79`,
	"booking-code-load-result__img": `booking-code-load-result__img_dTwKp`,
	"booking-code-load-result__header": `booking-code-load-result__header_gP3OD`,
	"booking-code-load-result__text": `booking-code-load-result__text_SAXEL`,
	"booking-code-load-result__button": `booking-code-load-result__button_Cov5K`
};
export default ___CSS_LOADER_EXPORT___;
