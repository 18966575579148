// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-slip-main_POZzi{position:relative;display:flex;flex:1 0 auto;flex-direction:column;overflow:hidden}.bet-slip-main--desktop_jAODM{flex:initial}.bet-slip-main__header_jOVRz{display:grid;grid-template-columns:repeat(3,1fr);height:44px}.bet-slip-main__list_MRIJ9{flex:none;padding:0;margin:0;list-style:none}.bet-slip-main__row_omfY0{position:relative;padding:0;margin:0}.bet-slip-main__row_omfY0:last-child{border-bottom:1px solid var(--Layer0)}.bet-slip-main__trace_VEcwK{z-index:5;position:absolute;right:2px;bottom:4px;z-index:99;flex:none;width:24px;height:24px;padding:3px;color:var(--AlertDefault);cursor:help;background:var(--Layer1);border:1px solid var(--AlertDefault);border-radius:50%}.bet-slip-main__singles-input_yI397{border-top:1px solid var(--Layer0)}.bet-slip-main__stake-input-wrapper__27qF{width:136px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-slip-main": `bet-slip-main_POZzi`,
	"bet-slip-main--desktop": `bet-slip-main--desktop_jAODM`,
	"bet-slip-main__header": `bet-slip-main__header_jOVRz`,
	"bet-slip-main__list": `bet-slip-main__list_MRIJ9`,
	"bet-slip-main__row": `bet-slip-main__row_omfY0`,
	"bet-slip-main__trace": `bet-slip-main__trace_VEcwK`,
	"bet-slip-main__singles-input": `bet-slip-main__singles-input_yI397`,
	"bet-slip-main__stake-input-wrapper": `bet-slip-main__stake-input-wrapper__27qF`
};
export default ___CSS_LOADER_EXPORT___;
