// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slip-trace-abbr_yQWk0{color:var(--BrandDefault);text-decoration-color:var(--TextSecondary);cursor:help}.slip-trace-label_cYn7K:before{content:" "}.slip-trace-table_gLQZd{width:100%;margin:10px 0;border-spacing:0;border-collapse:collapse}.slip-trace-table__cell_fjSMc{padding:5px;vertical-align:top;border:1px solid}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slip-trace-abbr": `slip-trace-abbr_yQWk0`,
	"slip-trace-label": `slip-trace-label_cYn7K`,
	"slip-trace-table": `slip-trace-table_gLQZd`,
	"slip-trace-table__cell": `slip-trace-table__cell_fjSMc`
};
export default ___CSS_LOADER_EXPORT___;
