// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-slip-result_DO6dg{position:absolute;top:0;right:0;bottom:0;left:0;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:var(--Layer1);opacity:.95;transition:opacity .25s ease}.bet-slip-result--completed_by670{opacity:1}.bet-slip-result--desktop_vwevT{position:static;align-items:flex-start;width:100%;padding-top:46px;background:none;opacity:1}.bet-slip-result__to-my-bets_QMGGJ{display:block;width:100%;padding:16px 0;margin:-16px 0;color:var(--TextSecondary);text-align:center;-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer;background-color:#0000;border:none}@media(hover:hover)and (pointer:fine){.bet-slip-result__to-my-bets_QMGGJ:hover{color:var(--TextDefault)}}.bet-slip-result__container_VjYMr{display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center;width:100%;padding:0 20px;text-align:center}.bet-slip-result--desktop_vwevT .bet-slip-result__container_VjYMr{justify-content:flex-start}.bet-slip-result__icon_R1re5{margin:0 0 20px;color:var(--TextSecondary)}.bet-slip-result__icon--success_gs3Pd{color:var(--BrandDefault)}.bet-slip-result__heading_ajmca{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;padding:0;margin:0 0 8px;color:var(--TextDefault)}.bet-slip-result__description_IZjI5,.bet-slip-result__to-my-bets_QMGGJ{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}.bet-slip-result__description_IZjI5{padding:0;margin:0}.bet-slip-result__description-second_UT7AF{margin-top:20px}.bet-slip-result__buttons_FUm2M{flex:none;align-self:stretch;padding:16px 16px 40px}.bet-slip-result__limits-button_I5Y9S{margin-bottom:8px}.bet-slip-result__button-top-margin_xyo4f{margin-top:8px}.bet-slip-result__close-clear-button_lqYMW{margin:20px 0 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-slip-result": `bet-slip-result_DO6dg`,
	"bet-slip-result--completed": `bet-slip-result--completed_by670`,
	"bet-slip-result--desktop": `bet-slip-result--desktop_vwevT`,
	"bet-slip-result__to-my-bets": `bet-slip-result__to-my-bets_QMGGJ`,
	"bet-slip-result__container": `bet-slip-result__container_VjYMr`,
	"bet-slip-result__icon": `bet-slip-result__icon_R1re5`,
	"bet-slip-result__icon--success": `bet-slip-result__icon--success_gs3Pd`,
	"bet-slip-result__heading": `bet-slip-result__heading_ajmca`,
	"bet-slip-result__description": `bet-slip-result__description_IZjI5`,
	"bet-slip-result__description-second": `bet-slip-result__description-second_UT7AF`,
	"bet-slip-result__buttons": `bet-slip-result__buttons_FUm2M`,
	"bet-slip-result__limits-button": `bet-slip-result__limits-button_I5Y9S`,
	"bet-slip-result__button-top-margin": `bet-slip-result__button-top-margin_xyo4f`,
	"bet-slip-result__close-clear-button": `bet-slip-result__close-clear-button_lqYMW`
};
export default ___CSS_LOADER_EXPORT___;
