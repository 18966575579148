// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-fast-bet_r7w8Q{display:flex;flex:1;flex-direction:column;padding:16px}.edit-fast-bet__caption_BKIm2{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;padding:16px 0;margin:0;color:var(--TextDefault)}.edit-fast-bet__default-sum_wGzPQ{padding:10px 0}.edit-fast-bet__default-sum-info_gtCnN{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;margin:8px 0 0;color:var(--TextSecondary)}.edit-fast-bet__default-sum-form_SFCvg{margin:10px 0 0}.edit-fast-bet__switch__BmFf{padding:10px 0}.edit-fast-bet__switch-hint_yVWDS{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin:0 0 10px;color:var(--TextPrimary)}.edit-fast-bet__max_V8HwF{color:var(--TextDefault)}.edit-fast-bet__toggle_rCJY4{margin-top:2px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit-fast-bet": `edit-fast-bet_r7w8Q`,
	"edit-fast-bet__caption": `edit-fast-bet__caption_BKIm2`,
	"edit-fast-bet__default-sum": `edit-fast-bet__default-sum_wGzPQ`,
	"edit-fast-bet__default-sum-info": `edit-fast-bet__default-sum-info_gtCnN`,
	"edit-fast-bet__default-sum-form": `edit-fast-bet__default-sum-form_SFCvg`,
	"edit-fast-bet__switch": `edit-fast-bet__switch__BmFf`,
	"edit-fast-bet__switch-hint": `edit-fast-bet__switch-hint_yVWDS`,
	"edit-fast-bet__max": `edit-fast-bet__max_V8HwF`,
	"edit-fast-bet__toggle": `edit-fast-bet__toggle_rCJY4`
};
export default ___CSS_LOADER_EXPORT___;
