// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.win-boost-info_B9uMZ{padding:16px 32px 32px;color:var(--TextPrimary)}.win-boost-info__description_jPXmg{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;padding:0;margin:0}.win-boost-info__table-background_SsrIk{padding:12px;margin-top:20px;background:var(--Layer1);border-radius:5px}.win-boost-table_nVn7F{width:100%;table-layout:fixed;border-collapse:collapse;border:none}.win-boost-table__caption_SUT2J{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;padding:0;margin:0 0 12px;text-align:left}.win-boost-table__row_vTPcH{border-bottom:2px solid var(--Layer1)}.win-boost-table__col-count_jMafR{text-align:left}.win-boost-table__col-boost_pssWX{width:60%;text-align:right}.win-boost-table__cell_VCckk{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;padding:12px;background:var(--Layer2)}.win-boost-table__cell--count_RjKmq{text-align:left}.win-boost-table__cell--boost_QqIgT{text-align:center}.win-boost-table__head_hry7N .win-boost-table__cell--count_RjKmq{border-radius:5px 0 0}.win-boost-table__head_hry7N .win-boost-table__cell--boost_QqIgT{border-radius:0 5px 0 0}.win-boost-table__body__YH6f .win-boost-table__row_vTPcH:last-child{border-bottom:none}.win-boost-table__body__YH6f .win-boost-table__row_vTPcH:last-child .win-boost-table__cell--count_RjKmq{border-radius:0 0 0 5px}.win-boost-table__body__YH6f .win-boost-table__row_vTPcH:last-child .win-boost-table__cell--boost_QqIgT{border-radius:0 0 5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"win-boost-info": `win-boost-info_B9uMZ`,
	"win-boost-info__description": `win-boost-info__description_jPXmg`,
	"win-boost-info__table-background": `win-boost-info__table-background_SsrIk`,
	"win-boost-table": `win-boost-table_nVn7F`,
	"win-boost-table__caption": `win-boost-table__caption_SUT2J`,
	"win-boost-table__row": `win-boost-table__row_vTPcH`,
	"win-boost-table__col-count": `win-boost-table__col-count_jMafR`,
	"win-boost-table__col-boost": `win-boost-table__col-boost_pssWX`,
	"win-boost-table__cell": `win-boost-table__cell_VCckk`,
	"win-boost-table__cell--count": `win-boost-table__cell--count_RjKmq`,
	"win-boost-table__cell--boost": `win-boost-table__cell--boost_QqIgT`,
	"win-boost-table__head": `win-boost-table__head_hry7N`,
	"win-boost-table__body": `win-boost-table__body__YH6f`
};
export default ___CSS_LOADER_EXPORT___;
