// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-slip-my-bets--desktop_qgPyw{display:flex;flex:0 1 auto;flex-direction:column;overflow:hidden}.bet-slip-my-bets--phone_Iompp{flex:1 0 auto;min-height:184px}.bet-slip-my-bets__loader_WhxtV{margin:20px}.bet-slip-my-bets__header_VkpdR{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;position:relative;display:flex;flex:none;align-items:center;justify-content:center;height:44px;color:var(--TextSecondary)}.bet-slip-my-bets__caption_h8XXq{margin:0 4px}.bet-slip-my-bets__footer_P3LQ3{flex:none;margin:-1px 0 0;text-align:center}.bet-slip-my-bets__footer--sticky_ABhfQ{z-index:5;position:sticky;bottom:0;margin-top:0;background-color:var(--Layer1);box-shadow:inset 0 1px 0 0 var(--Layer0)}.bet-slip-my-bets__details-link_s7krI{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;display:block;justify-content:center;padding:14px;color:var(--TextPrimary);text-align:center;-webkit-text-decoration:underline;text-decoration:underline;white-space:nowrap}.bet-slip-my-bets__details-link_s7krI:hover{color:var(--TextDefault)}.bet-slip-my-bets__details-link--fallback_hoqzN{position:absolute;right:16px}.bet-slip-my-bets__list_Sy2tY{flex:none;padding:0;margin:0;list-style:none}.bet-slip-my-bets__row_xboqh{padding:0;margin:0}.bet-slip-my-bets__empty-link_g6Dhy{margin-top:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-slip-my-bets--desktop": `bet-slip-my-bets--desktop_qgPyw`,
	"bet-slip-my-bets--phone": `bet-slip-my-bets--phone_Iompp`,
	"bet-slip-my-bets__loader": `bet-slip-my-bets__loader_WhxtV`,
	"bet-slip-my-bets__header": `bet-slip-my-bets__header_VkpdR`,
	"bet-slip-my-bets__caption": `bet-slip-my-bets__caption_h8XXq`,
	"bet-slip-my-bets__footer": `bet-slip-my-bets__footer_P3LQ3`,
	"bet-slip-my-bets__footer--sticky": `bet-slip-my-bets__footer--sticky_ABhfQ`,
	"bet-slip-my-bets__details-link": `bet-slip-my-bets__details-link_s7krI`,
	"bet-slip-my-bets__details-link--fallback": `bet-slip-my-bets__details-link--fallback_hoqzN`,
	"bet-slip-my-bets__list": `bet-slip-my-bets__list_Sy2tY`,
	"bet-slip-my-bets__row": `bet-slip-my-bets__row_xboqh`,
	"bet-slip-my-bets__empty-link": `bet-slip-my-bets__empty-link_g6Dhy`
};
export default ___CSS_LOADER_EXPORT___;
