// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-slip-select_jKoF3{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;position:relative;display:flex;flex:0;align-items:center;min-width:100px;height:44px;padding:0 4px 0 12px;color:var(--LTextDefault);white-space:nowrap;background-color:var(--LLayer2);border-radius:var(--BorderRadius)}.bet-slip-select--freebet_mi4e2{min-width:100%;padding-right:32px}.bet-slip-select--single-value_ieK4w{padding-right:32px;color:var(--LTextSecondary);cursor:default}.bet-slip-select--focused_CBaeI,.bet-slip-select_jKoF3:focus-within{box-shadow:inset 0 0 0 1px var(--LBlue)}.bet-slip-select__placeholder_s8PV4{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}.bet-slip-select__display_wV7Os{display:flex;flex:1;align-items:center;overflow:hidden}.bet-slip-select__label_k0CLD{flex:1;overflow:hidden}.bet-slip-select__value_T1iOg{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;flex:none;padding-left:8px}.bet-slip-select__select_n2jUS{position:absolute;top:0;bottom:0;left:0;width:100%;height:100%;color:currentcolor;-webkit-appearance:none;appearance:none;cursor:pointer;background-color:#0000;border:none;opacity:0}.bet-slip-select__select_n2jUS::-ms-expand{display:none}.bet-slip-select__arrow-icon_PVl4W{position:absolute;top:50%;right:8px;display:flex;align-items:center;justify-content:center;width:16px;height:16px;color:var(--LTextSecondary);pointer-events:none;transform:translateY(-50%)}.bet-slip-select--single-value_ieK4w .bet-slip-select__arrow-icon_PVl4W{right:12px;color:var(--LHighlight)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-slip-select": `bet-slip-select_jKoF3`,
	"bet-slip-select--freebet": `bet-slip-select--freebet_mi4e2`,
	"bet-slip-select--single-value": `bet-slip-select--single-value_ieK4w`,
	"bet-slip-select--focused": `bet-slip-select--focused_CBaeI`,
	"bet-slip-select__placeholder": `bet-slip-select__placeholder_s8PV4`,
	"bet-slip-select__display": `bet-slip-select__display_wV7Os`,
	"bet-slip-select__label": `bet-slip-select__label_k0CLD`,
	"bet-slip-select__value": `bet-slip-select__value_T1iOg`,
	"bet-slip-select__select": `bet-slip-select__select_n2jUS`,
	"bet-slip-select__arrow-icon": `bet-slip-select__arrow-icon_PVl4W`
};
export default ___CSS_LOADER_EXPORT___;
