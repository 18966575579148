import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, vShow as _vShow, normalizeClass as _normalizeClass, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDirective as _resolveDirective } from "vue";
import { VIcon } from '@components/v-icon';
import { VSpinner } from '@components/spinner';
import BetSlipDesktopDropdown from 'web/src/modules/slip/components/BetSlipSelect/BetSlipDesktopDropdown.vue';
import useBetSlipSelect from './composables/useBetSlipSelect';
import getOptionLabel from './utils/getOptionLabel';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipSelect',
    props: {
        isFreebet: {
            type: Boolean
        },
        name: {
            default: 'slipSelect'
        },
        selectedValue: {
            default: ''
        },
        options: {
            default: ()=>[]
        }
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isSingleValue, isFocused, displayLabel, displayValue, placeHolderText, setFocused, returnFocus, arrowIconProps, selectChange, emitChange } = useBetSlipSelect(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['bet-slip-select']]: true,
                    [_ctx.$style['bet-slip-select--freebet']]: props.isFreebet,
                    [_ctx.$style['bet-slip-select--focused']]: _unref(isFocused),
                    [_ctx.$style['bet-slip-select--single-value']]: _unref(isSingleValue)
                })
            }, [
                _withDirectives(_createVNode(_unref(VSpinner), {
                    class: _normalizeClass(_ctx.$style['bet-slip-select__placeholder'])
                }, null, 8, [
                    "class"
                ]), [
                    [
                        _vShow,
                        !_unref(displayLabel)
                    ]
                ]),
                _unref(displayLabel) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bet-slip-select__display'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['bet-slip-select__label'])
                        }, _toDisplayString(_unref(displayLabel)), 3),
                        _ctx.isFreebet && _unref(displayValue) ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['bet-slip-select__value'])
                        }, _toDisplayString(_unref(displayValue)), 3)) : _createCommentVNode("", true)
                    ], 2),
                    _unref(isSingleValue) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        (_openBlock(), _createBlock(BetSlipDesktopDropdown, {
                            key: 0,
                            name: _ctx.name,
                            options: _ctx.options,
                            "selected-value": _ctx.selectedValue,
                            "is-freebet": _ctx.isFreebet,
                            onOpen: _unref(setFocused),
                            onClose: _unref(returnFocus),
                            onChange: _unref(emitChange)
                        }, null, 8, [
                            "name",
                            "options",
                            "selected-value",
                            "is-freebet",
                            "onOpen",
                            "onClose",
                            "onChange"
                        ]))
                    ], //@ts-ignore
                    64)),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bet-slip-select__arrow-icon'])
                    }, [
                        _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(arrowIconProps))), null, 16)
                    ], 2)
                ], 64)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetSlipSelect'
                ]
            ]);
        };
    }
});
