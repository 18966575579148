import { ref, computed, watch } from 'vue';
import { logger } from '@leon-hub/logging';
import { isString } from '@leon-hub/guards';
import { useCopyToClipboard } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { handleImageBlob, isSupportShareApi, base64ToBlob } from '../utils';
export function useSharePreview(props, emit) {
    const { copy } = useCopyToClipboard();
    const { $translate } = useI18n();
    const copyCode = ()=>{
        copy({
            data: props.bookingCode ?? '',
            notificationText: $translate('WEB2_BET_SHARE_CODE_COPIED').value
        });
    };
    const savedImages = ref({});
    const updateSavedImages = (key, data)=>{
        savedImages.value[key] = data;
    };
    const imageFile = computed(()=>{
        if (props.imageUrl) return savedImages.value[props.imageUrl]?.file ?? null;
        return null;
    });
    const dataImageSrc = computed(()=>{
        if (props.imageUrl) return savedImages.value[props.imageUrl]?.dataUrl ?? '';
        return '';
    });
    const imageIsLoaded = ref(true);
    const imageName = computed(()=>{
        if (props.bookingCode) return `${props.bookingCode}.png`;
        return 'image.png';
    });
    const getImage = async ()=>{
        if (props.imageUrl) {
            const key = props.imageUrl;
            const imageBlob = base64ToBlob(props.imageUrl, 'image/png');
            if (imageBlob) {
                const file = new File([
                    imageBlob
                ], imageName.value, {
                    type: imageBlob.type
                });
                const dataUrl = await handleImageBlob(imageBlob);
                if (dataUrl && isString(dataUrl)) {
                    updateSavedImages(key, {
                        file,
                        dataUrl
                    });
                    imageIsLoaded.value = true;
                }
            }
        }
    };
    const imageSrc = computed(()=>{
        if (!imageIsLoaded.value) return null;
        return dataImageSrc.value;
    });
    const reloadImage = ()=>{
        imageIsLoaded.value = false;
        getImage();
    };
    watch(()=>props.imageUrl, (value)=>{
        if (value) reloadImage();
    }, {
        immediate: true
    });
    const emitDone = ()=>{
        emit('done');
    };
    const shareText = computed(()=>{
        if (props.isSharingPlacedBet) return $translate('WEB2_BET_SHARE_PLACED_BET_TEXT').value;
        if (!props.bookingCode) return $translate('WEB2_BET_SHARE_MESSAGE_TEXT').value;
        const codeTranslationProps = ref({
            code: props.bookingCode
        });
        return props.shareLink ? $translate('WEB2_BET_SHARE_MESSAGE_TEXT').value : $translate('WEB2_BET_SHARE_MESSAGE_TEXT_ONLY_CODE', codeTranslationProps).value;
    });
    const shareViaCopyLink = async ()=>{
        const linkStr = props.shareLink ? `\n${props.shareLink}` : '';
        await copy({
            data: `${shareText.value}${linkStr}`,
            notificationText: $translate('WEB2_LINK_HAS_BEEN_COPIED').value,
            isProfileModalTooltip: true
        });
        emitDone();
    };
    const downloadImage = ()=>{
        if (imageSrc.value) {
            const downloadLink = document.createElement('a');
            downloadLink.target = '_blank';
            downloadLink.href = imageSrc.value;
            downloadLink.download = imageName.value;
            downloadLink.click();
        }
    };
    const copyLinkOrSaveImage = ()=>{
        if (!props.shareLink && !props.imageUrl) {
            logger.error('Nothing to share in share preview', {
                ...props
            });
            return;
        }
        if (props.shareLink) shareViaCopyLink();
        else downloadImage();
    };
    const onMainButtonClick = ()=>{
        const shareData = {
            text: shareText.value,
            files: imageFile.value ? [
                imageFile.value
            ] : void 0
        };
        if (props.shareLink) shareData.url = props.shareLink;
        if (isSupportShareApi() && navigator.canShare(shareData)) navigator.share(shareData).then(()=>{
            emitDone();
            emit('share-success', props.bookingCode ?? '');
        }).catch((error)=>{
            logger.error('Bet share failed', error);
            copyLinkOrSaveImage();
        });
        else copyLinkOrSaveImage();
    };
    const onShowAmountToggle = ()=>{
        const nextValue = !props.showAmountEnabled;
        if (Object.keys(savedImages.value).length < 2) // not image from cache
        imageIsLoaded.value = false;
        emit('show-amount-click', nextValue);
    };
    const buttonIsDisabled = computed(()=>{
        if (props.isLoading) return true;
        return !!props.imageUrl && !imageIsLoaded.value;
    });
    return {
        copyCode,
        onMainButtonClick,
        imageIsLoaded,
        imageSrc,
        buttonIsDisabled,
        onShowAmountToggle,
        downloadImage
    };
}
