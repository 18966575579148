import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createSlots as _createSlots } from "vue";
import { onBeforeMount, onBeforeUnmount } from 'vue';
import { VFastMoneyChoice } from 'web/src/components/FastMoneyChoice';
import BetSlipSummary from '../components/BetSlipSummary';
import { BetSlipPlaceBetButton } from '../components/BetSlipPlaceBetButton';
import { useBetSlipFooter } from '../composable';
import { useSlipShare } from '../submodules/slip-share/composables';
import BetSlipKeyboard from '../components/BetSlipKeyboard/components/BetSlipKeyboard.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipFooter',
    setup (__props) {
        const { // BetSlipSummary
        betSlipSummaryProps, stakeInputFocus, stakeInputBlur, handleStakeInput, selectSystemValue, setSelectedFreeBet, onChangeFreeBetSwitch, allowPriceChanges, allowTotalHandicapChanges, toggleSameStakeForSingles, onClearBetList, showSlipSettings, // FastMoneyChoice
        isFastBetsEnabled, fastBetsProps, selectFastBet, // common keyboard
        isMobileKeyboardShown, hideKeyboard, backspace, numberInput, decimalSeparatorClick, editFastBets, // PlaceBetButton
        mainButtonProps, mainButtonClick, // life-cycle related
        onAppear, onDisappear } = useBetSlipFooter();
        const { isCurrentSlipShareEnabled, showSlipShareDialog } = useSlipShare();
        onBeforeMount(onAppear);
        onBeforeUnmount(onDisappear);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(BetSlipSummary), _mergeProps(_unref(betSlipSummaryProps), {
                "share-enabled": _unref(isCurrentSlipShareEnabled),
                onStakeFocus: _unref(stakeInputFocus),
                onStakeBlur: _unref(stakeInputBlur),
                onStakeInput: _unref(handleStakeInput),
                onSystemSelect: _unref(selectSystemValue),
                onFreebetSelect: _unref(setSelectedFreeBet),
                onFreebetSwitch: _unref(onChangeFreeBetSwitch),
                onAllowPriceChanges: _unref(allowPriceChanges),
                onAllowTotalHandicapChanges: _unref(allowTotalHandicapChanges),
                onToggleSameStakeForSingles: _unref(toggleSameStakeForSingles),
                onClearButtonClick: _unref(onClearBetList),
                onSettingsClick: _unref(showSlipSettings),
                onShareSlip: _unref(showSlipShareDialog)
            }), _createSlots({
                "fast-bets": _withCtx(()=>[
                        _unref(isFastBetsEnabled) ? (_openBlock(), _createBlock(_unref(VFastMoneyChoice), _mergeProps({
                            key: 0
                        }, _unref(fastBetsProps), {
                            onSelectValue: _unref(selectFastBet),
                            onEditClick: _unref(editFastBets)
                        }), null, 16, [
                            "onSelectValue",
                            "onEditClick"
                        ])) : _createCommentVNode("", true)
                    ]),
                button: _withCtx(()=>[
                        _withDirectives(_createVNode(_unref(BetSlipPlaceBetButton), _mergeProps(_unref(mainButtonProps), {
                            onClick: _unref(mainButtonClick)
                        }), null, 16, [
                            "onClick"
                        ]), [
                            [
                                _directive_data_test,
                                {
                                    el: 'bet-slip-button_summary',
                                    mode: _unref(mainButtonProps).mode
                                }
                            ]
                        ])
                    ]),
                _: 2
            }, [
                void 0
            ]), 1040, [
                "share-enabled",
                "onStakeFocus",
                "onStakeBlur",
                "onStakeInput",
                "onSystemSelect",
                "onFreebetSelect",
                "onFreebetSwitch",
                "onAllowPriceChanges",
                "onAllowTotalHandicapChanges",
                "onToggleSameStakeForSingles",
                "onClearButtonClick",
                "onSettingsClick",
                "onShareSlip"
            ])), [
                [
                    _directive_auto_id,
                    'BetSlipFooter'
                ]
            ]);
        };
    }
});
