import { getSlipEntryId } from '../../../utils';
import { updateBetsInfoItemBySlipEntry } from './updateBetsInfoItemBySlipEntry';
export function checkTotalHandicapReplacement(param) {
    let { oldEntries, updatedEntries, selectedEntriesIds, betsInfo, autoAcceptChanges } = param;
    if (oldEntries.length !== updatedEntries.length) return null;
    const updatedEntriesIds = updatedEntries.map((entry)=>getSlipEntryId(entry));
    const selectedEntriesSet = new Set([
        ...selectedEntriesIds
    ]);
    // todo: is this overhead?
    const changedItems = updatedEntriesIds.filter((item)=>!selectedEntriesSet.has(item));
    if (!changedItems.length || !changedItems.every((id)=>betsInfo[id].isTotalHandicap)) // no changed items or not of them are total handicap items
    return null;
    const updatedBetsInfo = {};
    const updatesSelectedEntriesIds = [];
    for(let i = 0; i < updatedEntries.length; i += 0){
        const updatedEntry = updatedEntries[i];
        const id = getSlipEntryId(updatedEntry);
        updatesSelectedEntriesIds.push(id);
        if (selectedEntriesSet.has(id)) {
            // entry was now replaced
            const oldBetsInfoItem = betsInfo[id];
            const updatedBetsInfoItem = updateBetsInfoItemBySlipEntry({
                sourceItem: oldBetsInfoItem,
                entry: updatedEntry
            });
            updatedBetsInfo[id] = updatedBetsInfoItem;
        } else {
            // this is replaced entry
            const oldEntry = oldEntries[i];
            const oldBetsInfoItem = betsInfo[getSlipEntryId(oldEntry)];
            // todo: review
            const updatedBetsInfoItem = updateBetsInfoItemBySlipEntry({
                sourceItem: oldBetsInfoItem,
                entry: updatedEntry
            });
            if (autoAcceptChanges) updatedBetsInfoItem.highlightReplacedItem = true;
            else updatedBetsInfoItem.oldRunnerName = oldEntry.runnerName ?? oldBetsInfoItem.runnerName;
            updatedBetsInfo[id] = updatedBetsInfoItem;
        }
    }
    return {
        betsInfo: updatedBetsInfo,
        selectedEntriesIds: updatesSelectedEntriesIds
    };
}
