// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .share-page-wrapper_fTgjZ{padding:0 28px 40px}html.app__layout--desktop .share-page-wrapper--centered_K3okO{display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"share-page-wrapper": `share-page-wrapper_fTgjZ`,
	"share-page-wrapper--centered": `share-page-wrapper--centered_K3okO`
};
export default ___CSS_LOADER_EXPORT___;
