// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-preview_etT77{color:var(--TextPrimary)}.share-preview__intro_o0wdg{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;padding:0;margin:0}.share-preview__loading_TQSOE{display:flex;align-items:center;justify-content:center;height:200px}.share-preview__image_fO2id{width:100%;object-fit:contain}.share-preview__input_iDb77{margin:14px 0 20px}.share-preview__copy_Mvw9o{color:var(--BrandDefault)}.share-preview__frame_KxoDR{position:relative;margin:0}.share-preview__download_IlIUg{position:absolute;top:0;right:0;bottom:0;left:0;display:flex;align-items:center;justify-content:center;cursor:pointer;background:#0000;border:none;opacity:0}.share-preview__download_IlIUg:hover{opacity:1}.share-preview__download-label_zqLwB{display:flex;align-items:center;justify-content:center;padding:8px;color:var(--TextPrimary);background:var(--Layer1);border-radius:10px}.share-preview__switcher_o00JT{margin:10px 0 0}.share-preview__button_xrDKa{margin:20px 0 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"share-preview": `share-preview_etT77`,
	"share-preview__intro": `share-preview__intro_o0wdg`,
	"share-preview__loading": `share-preview__loading_TQSOE`,
	"share-preview__image": `share-preview__image_fO2id`,
	"share-preview__input": `share-preview__input_iDb77`,
	"share-preview__copy": `share-preview__copy_Mvw9o`,
	"share-preview__frame": `share-preview__frame_KxoDR`,
	"share-preview__download": `share-preview__download_IlIUg`,
	"share-preview__download-label": `share-preview__download-label_zqLwB`,
	"share-preview__switcher": `share-preview__switcher_o00JT`,
	"share-preview__button": `share-preview__button_xrDKa`
};
export default ___CSS_LOADER_EXPORT___;
