// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-slip-settings-view_HQQZU{position:relative;overflow:hidden}.bet-slip-settings-view_HQQZU,.bet-slip-settings-view__scroll_lQkjk{display:flex;flex:1;flex-direction:column}.bet-slip-settings-view__scroll_lQkjk{position:relative;overflow-x:hidden;overflow-y:auto;overscroll-behavior:none;-webkit-overflow-scrolling:touch}.bet-slip-settings-view__tabs_fcQpS{flex:none}.bet-slip-settings-view__main_O0kMk{display:flex;flex-direction:column;overflow:hidden}.bet-slip-settings-view__caption_g81au{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;position:relative;display:flex;flex:0 0 auto;height:44px;padding:0 44px;color:var(--TextDefault)}.bet-slip-settings-view__caption-button_cXM2u{position:absolute;top:0;left:0;display:flex;align-items:center;justify-content:center;width:44px;height:44px;padding:0;color:var(--TextSecondary);cursor:pointer;background:#0000;border:none}@media(hover:hover)and (pointer:fine){.bet-slip-settings-view__caption-button_cXM2u:hover{color:var(--TextPrimary)}}.bet-slip-settings-view__caption-text_M0Vcr{display:flex;flex:1;align-items:center;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-slip-settings-view": `bet-slip-settings-view_HQQZU`,
	"bet-slip-settings-view__scroll": `bet-slip-settings-view__scroll_lQkjk`,
	"bet-slip-settings-view__tabs": `bet-slip-settings-view__tabs_fcQpS`,
	"bet-slip-settings-view__main": `bet-slip-settings-view__main_O0kMk`,
	"bet-slip-settings-view__caption": `bet-slip-settings-view__caption_g81au`,
	"bet-slip-settings-view__caption-button": `bet-slip-settings-view__caption-button_cXM2u`,
	"bet-slip-settings-view__caption-text": `bet-slip-settings-view__caption-text_M0Vcr`
};
export default ___CSS_LOADER_EXPORT___;
