// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slip-layout_ptszB{position:relative;display:flex;flex:1 0 100%;flex-direction:column;overflow:hidden auto}.slip-layout__content_NGBhM{position:relative;display:block;flex:1;padding-right:16px;margin-top:8px;overflow:hidden}.slip-layout__banners_jsPm3{flex:none;margin:40px 0 0}.slip-layout__banners_jsPm3:empty{display:none}.slip-layout__body_s7DaA{position:relative;flex:1;margin-bottom:8px;background-color:var(--Layer1);border-radius:10px}.slip-layout__body_s7DaA,.slip-layout__main_lxcHz{display:flex;flex-direction:column;overflow:hidden}.slip-layout__body_s7DaA,.slip-layout__overlay-content_gAlQ0{box-shadow:0 .66px 0 var(--Below)}.slip-layout__overlay_kBNcV{position:absolute;top:0;right:16px;bottom:0;left:0;z-index:8;display:flex;flex-direction:column;justify-content:stretch;background-color:var(--Layer0)}.slip-layout__pending-mask_F5dWc{position:absolute;top:0;right:0;bottom:84px;left:0;z-index:5;background-color:var(--Layer1);opacity:.95}.slip-layout__overlay-content_gAlQ0{margin-top:8px;background-color:var(--Layer1);border-radius:10px}.slip-layout__overlay-content--cashout_U8Ovs{padding:16px}.slip-layout__ssr-placeholder_Cqvvj{padding:40px 20px}.slip-layout__help_xK9mb{padding:16px;margin:0 auto}.slip-layout__help-button_Q5waO{min-width:160px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slip-layout": `slip-layout_ptszB`,
	"slip-layout__content": `slip-layout__content_NGBhM`,
	"slip-layout__banners": `slip-layout__banners_jsPm3`,
	"slip-layout__body": `slip-layout__body_s7DaA`,
	"slip-layout__main": `slip-layout__main_lxcHz`,
	"slip-layout__overlay-content": `slip-layout__overlay-content_gAlQ0`,
	"slip-layout__overlay": `slip-layout__overlay_kBNcV`,
	"slip-layout__pending-mask": `slip-layout__pending-mask_F5dWc`,
	"slip-layout__overlay-content--cashout": `slip-layout__overlay-content--cashout_U8Ovs`,
	"slip-layout__ssr-placeholder": `slip-layout__ssr-placeholder_Cqvvj`,
	"slip-layout__help": `slip-layout__help_xK9mb`,
	"slip-layout__help-button": `slip-layout__help-button_Q5waO`
};
export default ___CSS_LOADER_EXPORT___;
