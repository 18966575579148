import isSlipEntryRequestArray from '../../../guards/isSlipEntryRequestArray';
import { SlipTypeSingle } from '../../../constants';
export default function getSlipInfoRequestEntries(entries, slipType) {
    const clearEntries = entries.map((param)=>{
        let { // exact fields matters
        ts, event, market, runner, odds, banker, marketStatus, betline, zeroMargin, runnerTags, marketTypeIdentifier, marketTypeTag } = param;
        return {
            ts,
            event,
            market,
            runner,
            odds,
            banker,
            marketStatus,
            betline,
            zeroMargin,
            runnerTags,
            marketTypeIdentifier,
            marketTypeTag
        };
    });
    isSlipEntryRequestArray(clearEntries);
    if (slipType === SlipTypeSingle) return clearEntries.map((slipEntry)=>({
            strictChange: false,
            slipType,
            entries: [
                slipEntry
            ]
        }));
    return [
        {
            strictChange: false,
            slipType,
            entries: clearEntries
        }
    ];
}
