export default function getSlipEntryFromSlipListItem(item) {
    /** takes only necessary props to match the type
   * unexpected props lead to backend error */ return {
        runner: item.runner,
        market: item.market,
        marketStatus: item.marketStatus,
        event: item.event,
        banker: item.banker,
        odds: item.odds,
        oddsStr: item.oddsStr,
        zeroMargin: item.zeroMargin,
        betline: item.betline,
        competitors: item.competitors,
        runnerTags: item.runnerTags,
        marketTypeIdentifier: item.marketTypeIdentifier,
        marketTypeTag: item.marketTypeTag,
        ts: item.ts
    };
}
