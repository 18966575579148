import { MarketStatus } from '@leon-hub/api-sdk';
import formatOdd from 'web/src/utils/formatOdd';
import { SlipListBettingItemStatus } from '../../../components/SlipListItem/enums';
import isUnavailableEntryStatus from '../../../utils/isUnavailableEntryStatus';
import { getSafeCompetitorsValue } from './getSafeCompetitorsValue';
const getOriginalOddsString = (param)=>{
    let { originalValue, priceChangedMatters, formattedValue, marketStatus, isOddsFormatSwitcherEnabled } = param;
    if (isUnavailableEntryStatus(marketStatus) || !originalValue || !priceChangedMatters) return '';
    return isOddsFormatSwitcherEnabled ? formattedValue ?? '' : formatOdd(originalValue);
};
const getSlipListBettingItemStatus = (marketStatus)=>{
    switch(marketStatus){
        case MarketStatus.LIMIT:
            return SlipListBettingItemStatus.LIMIT;
        case MarketStatus.CLOSED:
        case MarketStatus.MISSING:
            return SlipListBettingItemStatus.BLOCKED;
        case MarketStatus.SUSPENDED:
            return SlipListBettingItemStatus.PAUSED;
        default:
            return SlipListBettingItemStatus.DEFAULT;
    }
};
const getMultiSingleMaxAvailableBet = (param)=>{
    let { restOfBalance, stakeValue, maxStake } = param;
    const maxViaBalance = restOfBalance + stakeValue;
    if (restOfBalance < 0) return 0;
    if (0 === restOfBalance) return stakeValue;
    if (maxStake && maxViaBalance > maxStake) return maxStake;
    return maxViaBalance;
};
export default function mapCombinedSlipEntryToExtendedSlipListBettingItemProps(param) {
    let { id, competitors, eventName, odds, oddsStr, oddChangeVariant, zeroMargin, marketName, runnerName, banker, marketStatus, metaInfo, originalOdds, originalOddsStr, matchedPriceChangePolicy, isTotalHandicap, oldRunnerName, highlightReplacedItem } = param, { isZeroMarginEnabled, disableBankers, isLive, errorMessage, restOfBalance, showAcceptButton, isOddsFormatSwitcherEnabled } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
        disableBankers: false,
        isLive: false,
        isZeroMarginEnabled: false,
        isOddsFormatSwitcherEnabled: false
    };
    const { minStake, maxStake, stakeValue } = metaInfo || {};
    return {
        id,
        competitors: getSafeCompetitorsValue(competitors),
        eventName: eventName ?? void 0,
        zeroMargin: zeroMargin && isZeroMarginEnabled,
        odd: isOddsFormatSwitcherEnabled ? oddsStr ?? '' : formatOdd(odds),
        oddChangeVariant,
        marketName: marketName ?? '',
        runnerName: runnerName ?? '',
        bankerActive: !!banker,
        bankerDisabled: !banker && disableBankers,
        status: getSlipListBettingItemStatus(marketStatus),
        isLive,
        stakeValue: `${stakeValue || ''}`,
        errorMessage,
        minStake: minStake || 0,
        maxStake: maxStake || void 0,
        maxAvailableBet: getMultiSingleMaxAvailableBet({
            restOfBalance: restOfBalance || 0,
            stakeValue: Number(stakeValue || 0),
            maxStake
        }),
        needToAcceptChanges: showAcceptButton,
        originalOdd: getOriginalOddsString({
            marketStatus: marketStatus ?? null,
            originalValue: originalOdds ?? 0,
            priceChangedMatters: !matchedPriceChangePolicy,
            isOddsFormatSwitcherEnabled,
            formattedValue: originalOddsStr
        }),
        isTotalHandicap,
        oldRunnerName,
        isReplacedItem: highlightReplacedItem
    };
}
