import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { isString } from '@leon-hub/guards';
import { HomePageType } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useGetSharedSlip } from '../submodules/get-shared-slip/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'GetSharedSlipByLinkRoutePage',
    setup (__props) {
        const { isReceiveFromCodeEnabled, getSlipFromBookingCode, setSharedBookingCode } = useGetSharedSlip();
        const route = useRoute();
        const router = useRouter();
        const { homePageType } = useSiteConfigStore();
        onMounted(()=>{
            const { code } = route.params;
            const { query } = route;
            if (isString(code) && isReceiveFromCodeEnabled.value) {
                setSharedBookingCode(code);
                getSlipFromBookingCode(true);
            }
            const sportHome = homePageType === HomePageType.SPORTS ? RouteName.HOME : RouteName.SPORTLINE_TOP_EVENTS;
            router.replace({
                name: sportHome,
                query
            });
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, null, 512)), [
                [
                    _directive_auto_id,
                    'GetSharedSlipByLinkRoutePage'
                ]
            ]);
        };
    }
});
