// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-code-form_yhBeu{margin:30px 0 0}.booking-code-form_yhBeu:not(:last-child){margin-bottom:-10px}.booking-code-form__footer_lZSfi{margin:8px 0 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"booking-code-form": `booking-code-form_yhBeu`,
	"booking-code-form__footer": `booking-code-form__footer_lZSfi`
};
export default ___CSS_LOADER_EXPORT___;
