// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.win-boost-info_rlCKZ{display:flex;flex:none;align-items:stretch;overflow:hidden;background:var(--OpacityBrandDefault);border:solid var(--Layer0);border-width:8px 0}.win-boost-info__help_fHgmX{display:flex;flex:none;align-items:center;justify-content:center;width:32px;color:var(--TextSecondary);cursor:help;background:none;border:none}.win-boost-info__main_umIax{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;display:flex;flex:1;flex-direction:column;padding:8px 0;color:var(--TextDefault)}.win-boost-info__line_rno6N{padding:0;margin:0}.win-boost-info__line--primary_nHzL4{color:var(--TextPrimary)}.win-boost-info__right_XnkiN{display:flex;flex:none;align-items:center;justify-content:center;padding:0 12px}.win-boost-info__bonus_NvQaQ{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;padding:4px;color:var(--BrandHighlight);white-space:nowrap;background:var(--OpacityBrandDefault);border-radius:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"win-boost-info": `win-boost-info_rlCKZ`,
	"win-boost-info__help": `win-boost-info__help_fHgmX`,
	"win-boost-info__main": `win-boost-info__main_umIax`,
	"win-boost-info__line": `win-boost-info__line_rno6N`,
	"win-boost-info__line--primary": `win-boost-info__line--primary_nHzL4`,
	"win-boost-info__right": `win-boost-info__right_XnkiN`,
	"win-boost-info__bonus": `win-boost-info__bonus_NvQaQ`
};
export default ___CSS_LOADER_EXPORT___;
