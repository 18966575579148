import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { ButtonKind } from '@leon-hub/module-buttons';
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { InputButton, TextInput } from 'web/src/components/Input';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import { VJumbotron } from 'web/src/components/Jumbotron';
import { useSharePreview } from '../composables';
import { isSupportShareApi } from '../utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SharePreview',
    props: {
        isLoading: {
            type: Boolean
        },
        bookingCode: {},
        imageUrl: {},
        shareLink: {},
        showStakeAmountSwitcher: {
            type: Boolean
        },
        showAmountEnabled: {
            type: Boolean
        },
        isSharingPlacedBet: {
            type: Boolean
        },
        errorMessage: {}
    },
    emits: [
        "done",
        "share-success",
        "show-amount-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { copyCode, onMainButtonClick, imageIsLoaded, imageSrc, buttonIsDisabled, onShowAmountToggle, downloadImage } = useSharePreview(props, emits);
        const showFallbackDownloadOverlay = !isSupportShareApi();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['share-preview'])
            }, [
                _ctx.errorMessage ? (_openBlock(), _createBlock(_unref(VJumbotron), {
                    key: 0,
                    icon: _unref(IconName).ATTENTION_OUTLINE,
                    heading: _ctx.isSharingPlacedBet ? _ctx.$t('WEB2_SHARE_BET_ERROR_HEADING') : _ctx.$t('WEB2_SHARE_SLIP_ERROR_HEADING'),
                    text: _ctx.errorMessage
                }, {
                    footer: _withCtx(()=>[
                            _createVNode(VButton, {
                                label: _ctx.$t('WEB2_CLOSE'),
                                "full-width": "",
                                kind: _unref(ButtonKind).PRIMARY,
                                onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('done'))
                            }, null, 8, [
                                "label",
                                "kind"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "icon",
                    "heading",
                    "text"
                ])) : _ctx.isLoading ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['share-preview__loading'])
                }, [
                    _createVNode(_unref(VLoaderDelayed))
                ], 2)) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 2
                }, [
                    _ctx.bookingCode ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createElementVNode("p", {
                            class: _normalizeClass(_ctx.$style['share-preview__intro'])
                        }, _toDisplayString(_ctx.$t('WEB2_BET_SHARE_INTRO')), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['share-preview__input'])
                        }, [
                            _createVNode(_unref(TextInput), {
                                label: _ctx.$t('WEB2_BET_SHARE_BOOKING_CODE'),
                                value: _ctx.bookingCode,
                                readonly: "",
                                "clickable-icon": ""
                            }, {
                                iconSuffix: _withCtx(()=>[
                                        _createVNode(_unref(InputButton), {
                                            title: _ctx.$t('WEB2_COPY'),
                                            "icon-name": _unref(IconName).FILES,
                                            class: _normalizeClass(_ctx.$style['share-preview__copy']),
                                            onClick: _unref(copyCode)
                                        }, null, 8, [
                                            "title",
                                            "icon-name",
                                            "class",
                                            "onClick"
                                        ])
                                    ]),
                                _: 1
                            }, 8, [
                                "label",
                                "value"
                            ])
                        ], 2)
                    ], 64)) : _createCommentVNode("", true),
                    _ctx.imageUrl || !_unref(imageIsLoaded) ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['share-preview__frame'])
                    }, [
                        _unref(imageIsLoaded) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            showFallbackDownloadOverlay ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                type: "button",
                                class: _normalizeClass(_ctx.$style['share-preview__download']),
                                onClick: _cache[1] || (_cache[1] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(downloadImage) && _unref(downloadImage)(...args);
                                })
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['share-preview__download-label'])
                                }, [
                                    _createVNode(_unref(VIcon), {
                                        name: _unref(IconName).DOWNLOAD_2
                                    }, null, 8, [
                                        "name"
                                    ]),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('WEB2_DOWNLOAD')), 1)
                                ], 2)
                            ], 2)) : _createCommentVNode("", true),
                            _createElementVNode("img", {
                                src: _unref(imageSrc) ?? '',
                                alt: "preview",
                                class: _normalizeClass(_ctx.$style['share-preview__image'])
                            }, null, 10, _hoisted_1)
                        ], 64)) : (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['share-preview__loading'])
                        }, [
                            _createVNode(_unref(VLoaderDelayed))
                        ], 2))
                    ], 2)) : _createCommentVNode("", true),
                    _ctx.showStakeAmountSwitcher ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['share-preview__switcher'])
                    }, [
                        _createVNode(VSwitch, {
                            label: _ctx.$t('WEB2_BET_SHARE_SHOW_AMOUNT'),
                            checked: _ctx.showAmountEnabled,
                            disabled: !_unref(imageIsLoaded),
                            onChange: _unref(onShowAmountToggle)
                        }, null, 8, [
                            "label",
                            "checked",
                            "disabled",
                            "onChange"
                        ])
                    ], 2)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['share-preview__button'])
                    }, [
                        _createVNode(VButton, {
                            label: _ctx.$t('WEB2_BET_SHARE_CTA_BUTTON'),
                            "full-width": "",
                            kind: _unref(ButtonKind).PRIMARY,
                            disabled: _unref(buttonIsDisabled),
                            onClick: _unref(onMainButtonClick)
                        }, null, 8, [
                            "label",
                            "kind",
                            "disabled",
                            "onClick"
                        ])
                    ], 2)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'SharePreview'
                ]
            ]);
        };
    }
});
