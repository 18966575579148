import { computed, toRef } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCountryStore } from 'web/src/modules/country/store';
import ServerDate from 'web/src/utils/ServerDate';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useSlipInfoStore } from '../submodules/slip-info/store';
/**
 * LEONWEB-14699
 * https://docs.google.com/document/d/11mZcLO99c-1jUNtVDGZECrtyZMBCAHMNkTpzxkpLZOc/edit?usp=sharing
 * */ // 'https://3956.fun/' or any site mirror with Page Path
// country code;
// 'single' or system, or any other relevant value
// number of events included in the coupon
// '6.57' or '2 | 1.2 | 3.3' for multisingles,
export function useShareEventLogging() {
    const { pushGTM } = useAnalytics();
    const { isLoggedIn } = useIsLoggedIn();
    const customerDataStore = useCustomerDataStore();
    const customerId = toRef(customerDataStore, 'login');
    const isVip = toRef(customerDataStore, 'isVip');
    const countryCode = toRef(useCountryStore(), 'countryCode');
    const getCommonLogData = ()=>({
            customer_id: customerId.value,
            customer_status: isLoggedIn.value ? 'logged_in' : 'logged_out',
            vs_lw: isVip.value,
            full_url: document.location.href,
            country: countryCode.value
        });
    const slipInfoStore = useSlipInfoStore();
    const slipEventsCount = toRef(slipInfoStore, 'slipEventsCount');
    const allOdds = toRef(slipInfoStore, 'allOdds');
    const betMode = toRef(slipInfoStore, 'betMode');
    const oddsStr = computed(()=>allOdds.value.join(' | '));
    const betType = computed(()=>betMode.value.toLowerCase());
    const getSuccessPayload = (shareCode)=>({
            ...getCommonLogData(),
            bet_type: betType.value,
            bet_count: slipEventsCount.value,
            bet_total_odd: oddsStr.value,
            bet_share_code: shareCode
        });
    const logShareSuccess = function() {
        let shareCode = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        pushGTM(AnalyticsEvent.BET_SHARE, {
            ...getSuccessPayload(shareCode),
            bet_share_timestamp: ServerDate.now()
        });
    };
    const logUnableToShare = (message)=>{
        pushGTM(AnalyticsEvent.BET_SHARE_ERROR, {
            ...getCommonLogData(),
            bet_type: betType.value,
            bet_count: slipEventsCount.value,
            bet_share_error_message: message,
            bet_share_error_timestamp: ServerDate.now()
        });
    };
    const logGetSharedCouponSuccess = (shareCode)=>{
        pushGTM(AnalyticsEvent.SHARED_BET_LOAD, {
            ...getSuccessPayload(shareCode),
            bet_load_timestamp: ServerDate.now()
        });
    };
    const logGetSharedCouponError = (shareCode, message)=>{
        pushGTM(AnalyticsEvent.SHARED_BET_ERROR, {
            ...getCommonLogData(),
            bet_share_code: shareCode,
            bet_load_error_message: message,
            bet_load_error_timestamp: ServerDate.now()
        });
    };
    return {
        logShareSuccess,
        logUnableToShare,
        logGetSharedCouponSuccess,
        logGetSharedCouponError
    };
}
