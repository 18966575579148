// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-slip-clear-overlay_bWnkq{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:16px}.bet-slip-clear-overlay__icon_Z4mN5{width:60px;height:60px;color:var(--TextSecondary)}.bet-slip-clear-overlay__label_OEFbd{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin:3px 0 20px;color:var(--TextPrimary);text-align:center}.bet-slip-clear-overlay__button_t4ptr{width:100%}.bet-slip-clear-overlay__button--cancel_zuQ4d{margin-top:20px}.bet-slip-clear-overlay__footer_lEA0d,.bet-slip-clear-overlay__label_OEFbd{align-self:stretch}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-slip-clear-overlay": `bet-slip-clear-overlay_bWnkq`,
	"bet-slip-clear-overlay__icon": `bet-slip-clear-overlay__icon_Z4mN5`,
	"bet-slip-clear-overlay__label": `bet-slip-clear-overlay__label_OEFbd`,
	"bet-slip-clear-overlay__button": `bet-slip-clear-overlay__button_t4ptr`,
	"bet-slip-clear-overlay__button--cancel": `bet-slip-clear-overlay__button--cancel_zuQ4d`,
	"bet-slip-clear-overlay__footer": `bet-slip-clear-overlay__footer_lEA0d`
};
export default ___CSS_LOADER_EXPORT___;
