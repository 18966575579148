import getSlipEntryId from '../../../utils/getSlipEntryId';
import { getSafeCompetitorsValue } from './getSafeCompetitorsValue';
export default function convertSlipListItemToBetsInfoObject(slipListItem) {
    const id = getSlipEntryId(slipListItem);
    const { competitors, runnerName, marketName, eventName, odds, oddsStr, isTotalHandicap, isPrimaryMarket } = slipListItem;
    return {
        [id]: {
            competitors: getSafeCompetitorsValue(competitors),
            runnerName: runnerName ?? '',
            marketName: marketName ?? '',
            eventName: eventName ?? void 0,
            originalOdds: odds,
            originalOddsStr: oddsStr ?? void 0,
            isPrimaryMarket: isPrimaryMarket ?? false,
            isTotalHandicap
        }
    };
}
