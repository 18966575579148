import { getSlipEntryId } from 'web/src/modules/slip/utils';
import { updateBetsInfoItemBySlipEntry } from './updateBetsInfoItemBySlipEntry';
/** use to update slip entries what was has no replaced total/handlicap entries */ export function updateBetsInfoByCurrentEntries(betsInfo, currentEntries) {
    const result = {
        ...betsInfo
    };
    for (const item of currentEntries){
        const id = getSlipEntryId(item);
        const matchedRecord = betsInfo[id];
        const updatedRecord = updateBetsInfoItemBySlipEntry({
            sourceItem: matchedRecord,
            entry: item
        });
        result[id] = updatedRecord;
    }
    return result;
}
