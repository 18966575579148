// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-slip-trace_fEvb4{display:flex;flex:1;flex-direction:column;overflow:hidden;color:var(--TextPrimary);text-align:left;cursor:auto;-webkit-user-select:auto;user-select:auto}.bet-slip-trace__heading_FVaqL{flex:none;padding:0 5px;margin:0 0 10px;font-size:150%}.bet-slip-trace__content_x6_te{flex:1;padding-right:20px;margin-right:-20px;overflow-y:auto}.bet-slip-trace__table_explj{width:100%;line-height:1.3;table-layout:fixed;border-spacing:0}.bet-slip-trace__row_vDWLh{font-weight:400}.bet-slip-trace__row--error_gQjxa{color:var(--ErrorText)}.bet-slip-trace__cell_ru9DB{padding:3px 5px;vertical-align:top}.bet-slip-trace__cell--time_b2lZf{width:80px}.bet-slip-trace__cell--content_j70GI{white-space:pre}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-slip-trace": `bet-slip-trace_fEvb4`,
	"bet-slip-trace__heading": `bet-slip-trace__heading_FVaqL`,
	"bet-slip-trace__content": `bet-slip-trace__content_x6_te`,
	"bet-slip-trace__table": `bet-slip-trace__table_explj`,
	"bet-slip-trace__row": `bet-slip-trace__row_vDWLh`,
	"bet-slip-trace__row--error": `bet-slip-trace__row--error_gQjxa`,
	"bet-slip-trace__cell": `bet-slip-trace__cell_ru9DB`,
	"bet-slip-trace__cell--time": `bet-slip-trace__cell--time_b2lZf`,
	"bet-slip-trace__cell--content": `bet-slip-trace__cell--content_j70GI`
};
export default ___CSS_LOADER_EXPORT___;
