import { computed, ref } from 'vue';
import { priceChangePolicyDenyChanges, priceChangePolicyAllowChanges, priceChangePolicyAllowIncrease } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
/** not a getter only to not become unchecked while collapse animation playing */ export default function useSlipCommonSettings(props, emit) {
    const analytics = useAnalytics();
    const autoUpdateEnabled = computed(()=>props.priceChangePolicy !== priceChangePolicyDenyChanges);
    const onlyIncrease = ref(props.priceChangePolicy === priceChangePolicyAllowIncrease);
    const acceptsTotalHandicapChanges = computed(()=>props.totalHandicapPriceChangePolicy === priceChangePolicyAllowChanges);
    const emitChangePolicySelect = (value)=>{
        emit('change-policy-select', value);
    };
    const emitTotalHandicapPolicySelect = (value)=>{
        emit('total-handicap-policy-select', value);
    };
    const onAutoUpdateChange = (value)=>{
        const newValue = autoUpdateEnabled.value ? priceChangePolicyDenyChanges : priceChangePolicyAllowChanges;
        emitChangePolicySelect(newValue);
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                autoUpdateCoeff: value ? 'turnOn' : 'turnOff'
            }
        });
    };
    const toggleOnlyIncrease = ()=>{
        const newValue = onlyIncrease.value ? priceChangePolicyAllowChanges : priceChangePolicyAllowIncrease;
        onlyIncrease.value = !onlyIncrease.value;
        emitChangePolicySelect(newValue);
    };
    const onTotalHandicapSwitch = (value)=>{
        emitTotalHandicapPolicySelect(value ? priceChangePolicyAllowChanges : priceChangePolicyDenyChanges);
    };
    const resetOnlyIncreaseValue = ()=>{
        if (!autoUpdateEnabled.value) onlyIncrease.value = false;
    };
    return {
        autoUpdateEnabled,
        onlyIncrease,
        acceptsTotalHandicapChanges,
        onAutoUpdateChange,
        toggleOnlyIncrease,
        resetOnlyIncreaseValue,
        onTotalHandicapSwitch
    };
}
