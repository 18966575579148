// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-slip-desktop-system-dropdown__button_c_3Ss{position:absolute;top:0;bottom:0;left:0;width:100%;height:100%;-webkit-appearance:none;appearance:none;cursor:pointer;background-color:#0000;border:none;opacity:0}.bet-slip-desktop-system-dropdown__mask_qfEg_{position:absolute;top:0;right:0;bottom:0;left:0;z-index:2;cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-slip-desktop-system-dropdown__button": `bet-slip-desktop-system-dropdown__button_c_3Ss`,
	"bet-slip-desktop-system-dropdown__mask": `bet-slip-desktop-system-dropdown__mask_qfEg_`
};
export default ___CSS_LOADER_EXPORT___;
