// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-slip-tabs_zI7SM{display:flex;flex-shrink:0;padding:4px 4px 0;margin:0;overflow:hidden}@media(hover:hover)and (pointer:fine){.bet-slip-tabs__button_iAhJZ:hover .bet-slip-tabs__count_dDHUt{background-color:var(--TextDefault)}}.bet-slip-tabs__list_J7mqA{display:flex;flex:1;padding:2px;margin:0;overflow:hidden;list-style:none;background-color:var(--Layer2);border-radius:7px}.bet-slip-tabs__item_MsgWc{position:relative;display:flex;flex:1 1 50%;height:40px}.bet-slip-tabs__item_MsgWc:only-child{flex-basis:100%}.bet-slip-tabs__button_iAhJZ{display:flex;flex:1;align-items:center;justify-content:center;height:40px;padding:0 16px;font-size:14px;font-weight:500;color:var(--TextSecondary);text-align:center;white-space:nowrap;cursor:pointer;-webkit-user-select:none;user-select:none;background:none;border:none;border-radius:5px}@media(hover:hover)and (pointer:fine){.bet-slip-tabs__button_iAhJZ:hover{color:var(--TextDefault)}}.bet-slip-tabs__button--active_bqONk{color:var(--TextDefault);cursor:default;background-color:var(--Highlight)}.bet-slip-tabs__count_dDHUt{font-size:12px;font-weight:700;line-height:16px;letter-spacing:-.7px;min-width:16px;padding:0 4px;margin-left:4px;color:var(--Layer1);text-align:center;letter-spacing:normal;background-color:var(--TextSecondary);border-radius:8px}.bet-slip-tabs__count--active_UQoit{color:var(--Highlight);background-color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-slip-tabs": `bet-slip-tabs_zI7SM`,
	"bet-slip-tabs__button": `bet-slip-tabs__button_iAhJZ`,
	"bet-slip-tabs__count": `bet-slip-tabs__count_dDHUt`,
	"bet-slip-tabs__list": `bet-slip-tabs__list_J7mqA`,
	"bet-slip-tabs__item": `bet-slip-tabs__item_MsgWc`,
	"bet-slip-tabs__button--active": `bet-slip-tabs__button--active_bqONk`,
	"bet-slip-tabs__count--active": `bet-slip-tabs__count--active_UQoit`
};
export default ___CSS_LOADER_EXPORT___;
